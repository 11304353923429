<template>
  <div>
    <div class="toppicd">
      <img src="../../../Newimg/zhengce.jpg" alt="" />
    </div>
    <div class="bg">
      <div class="document-body">
        <div class="document">
          <div class="search" style="background: #fff">
            <el-input
              placeholder="请输入内容查询"
              v-model="keywords"
              @change="submit"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="submit"
              ></el-button>
            </el-input>
          </div>
          <div class="policy">
            <div class="policy-title">
              <span class="title-name">{{ showdata.title }}</span>
            </div>
            <div class="title-subtitle">{{ showdata.name }}</div>
            <div
              class="title-jieshao"
              v-html="changeColor(content[page])"
            ></div>
            <div class="title-time">{{ showdata.modifytime }}</div>
          </div>
          <div class="pagination">
            <el-pagination
              class="fy"
              small
              :page-size="1"
              layout="total, prev, pager, next, jumper"
              @current-change="current_change"
              :total="content.length"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div class="document-right">
          <div class="document-options">
            <div class="options-item" @click="gotougao">
              <img :src="require('@/assets/image/tougao.png')" alt="" />
            </div>
            <div class="options-item" @click="goqikan">
              <img :src="require('@/assets/image/ruzhu.png')" alt="" />
            </div>
          </div>
          <div class="document-hot">
            <div class="document-hot-title">热门政策</div>
            <div class="document-hot-list">
              <div
                class="document-hot-item"
                v-for="(item, index) in hot_list"
                :key="index"
                @click="showpolicydetail(item.id)"
              >
                <div
                  class="document-hot-rank"
                  :class="
                    index == 0
                      ? 'color1'
                      : index == 1
                      ? 'color2'
                      : index == 2
                      ? 'color3'
                      : ''
                  "
                >
                  {{ index + 1 }}
                </div>
                <!-- v-html="item.wx_name" -->

                <div class="document-item-title">
                  <div
                    class="document-item-title"
                    style="padding: 0; border: none"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="document-ad">
            <el-carousel height="150px" arrow="never">
              <el-carousel-item
                v-for="(item, index) in bannerrList"
                :key="index"
              >
                <img
                  :src="url + item.cover"
                  @click="golink(item.link)"
                  alt=""
                  class="carousel-img"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  policyDetails,
  getHotPolicy,
  Carousel,
} from "@/API/homepage/policy.js";
import Topic from "@/components/Topics.vue";

export default {
  components: {
    Topic,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      showdata: [],
      page: 0,
      bgcolor: "#fff",
      hot_list: [],
      bannerrList: [],
      content: [],
      url: "",
      id: 0,
      keywords: "",
      searchhead: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getpolicyDetail();
    this.url = this.URL;
    this.getHotPolicy();
    this.CarouseList();
    this.url = this.URL;
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.getpolicyDetail();
      }
    },
  },
  methods: {
    changeColor(item) {
      var keywords = this.keywords; //获取动态变化的搜索词
      this.searchhead = 0;

      if (keywords !== "") {
        if (/.*[\u4e00-\u9fa5]+.*$/.test(keywords)) {
          //若搜索词不为空，对搜索词进行替换
          return item.replace(
            new RegExp(keywords, "g"),
            '<b style="background:yellow;" >' + keywords + "</b>"
          );
        } else {
          return item;
        }
      } else {
        return item;
      }
    },
    submit() {
      let num = document.getElementsByTagName("b").length; //获取所有a标签的数量，这个页面所有的a标签都是由查询替换获得的，所以a标签的数量可以当场查询到关键词的数量
      if (num != 0) {
        //如果查询关键词存在，跳到第一个关键词的位置，标头增1，走满一圈归0
        document.getElementsByTagName("b")[this.searchhead].scrollIntoView(); //scrollIntoView方法只在原生document中可以使用，jquery中没有这个方法，参数默认是true，将这个元素置于页面第一行（如果页面可以滑动函数才生效）
        if (this.searchhead < num - 1) {
          this.searchhead += 1;
        } else if (this.searchhead == num - 1) {
          this.searchhead = 0;
        }
      }
    },
    CarouseList() {
      Carousel(2).then((res) => {
        this.bannerrList = res.data.data;
      });
    },
    current_change: function (currentPage) {
      this.page = currentPage - 1;
      console.log(this.page);
    },
    golink(url) {
      if (url == "") {
        return;
      }
      window.location.href = url;
    },
    showpolicydetail(e) {
      this.$router.push({
        path: "/policy",
        query: {
          id: e,
        },
      });
    },
    getHotPolicy() {
      getHotPolicy().then((res) => {
        this.hot_list = res.data.data;
      });
    },
    gotougao() {
      this.$router.push("/Pending/contribute");
    },
    goqikan() {
      this.$router.push("/enter?now=2");
    },
    pingjia2(e) {
      this.xj2 = e + 1;
    },
    getpolicyDetail() {
      policyDetails(this.id).then((res) => {
        this.showdata = res.data.data;
        this.content = res.data.data.content.split("_ueditor_page_break_tag_");
      });
    },
  },
};
</script>

<style scoped>
.start {
  width: 20px;
  cursor: pointer;
}
.magzinepolicy {
  padding-bottom: 30px;
}
.magzinepolicy .mthem {
  text-align: center;
  height: 42px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 42px;
  margin-bottom: 20px;
  padding-top: 30px;
}
.policy-title {
  text-align: center;
}

.magpic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.magpic div {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin-bottom: 10px;
}
.magpic div span {
  display: block;
  margin: 8px 0;
}
.magpic div img {
  width: 180px;
}
.mthems {
  margin: auto;
  width: 68px;
  height: 3px;
  background: #ff1918;
  border-radius: 0px;
  opacity: 0.42;
}
.mcont {
  margin-top: -60px;
  background: #fff;
  border-radius: 14px;
}
.policy {
  width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 40px 0;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.title-name {
  width: auto;
  height: 25px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #353535;
  line-height: 25px;
  text-align: center;
  width: 100%;
}
.title-subtitle {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.title-time {
  width: auto;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #353535;
  margin-top: 46px;
  line-height: 17px;
  width: 100%;
  text-align: right;
}
.title-bookname {
  width: auto;
  height: 25px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
}
.title-jieshao {
  width: auto;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-top: 26px;
}
.document-body {
  display: flex;
  width: 80%;
  margin: 30px auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
}
.document-right {
  width: 24%;
}
.document-right .document-options .options-item img {
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}
.document-ad {
  background: #e5e5e5;
  border-radius: 16px;
  margin-top: 20px;
}
.document {
  width: 74%;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  padding: 30px;
  border-radius: 16px;
}
.document-hot {
  background: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px 16px;
  border-radius: 12px;
}
.document-hot .document-hot-title {
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.document-hot .document-hot-list {
  margin-top: 16px;
}
.document-hot .document-hot-list .document-hot-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 16px;
}
.document-hot-rank {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2d2d2;
  color: #fff;
  margin-right: 16px;
}
.document-item-title {
  flex: 1;
  font-size: 14px;
  color: #a2a2a2;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 22px;
  height: 44px;
  padding-bottom: 16px;
  transition: all 0.2s;
}
.document-item-title:hover {
  color: #535353;
  height: 100%;
  display: block;
}
.color1 {
  background: #fa9999;
}
.color2 {
  background: #f7b697;
}
.color3 {
  background: #a5d8a1;
}

.small {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  display: flex;
  width: 70%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 26px;
  margin-bottom: 20px;
}
.weizhi {
  box-sizing: border-box;
  height: 80px;
  line-height: 100px;
  font-size: 14px;
  color: #939393;
}
</style>
<style>
.search .el-input-group .el-input__inner {
  border: none !important;
  height: 38px;
  border-radius: 20px;
}
.search .el-input-group .el-input-group__append {
  background: none;
  border: none;
  color: #75a1ea;
  font-size: 20px;
  font-weight: bold;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>